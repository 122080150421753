/**
 * tabs data take property name of tabs,
 * tabs can contain multiple active tabs data if same page have multiple tabs
 * example if there is tabs1 and tabs2
 * tabs data will have tabs1 and tabs2 as property
 * by default activeTabs setted as propert to common use
 */

export default {
	name: 'activeTabMixin',
	data: () => ({
		tabs: {
			activeTabs: 0
		}
	}),
	methods: {
		/**
		 *
		 * @param {String} context property name of tabs to use, example activeTab
		 * @param {number} index // tab index
		 */
		onTabClicked(index, context = 'activeTabs') {
			const currentParams = { ...this.$route.query };
			const queryValue = `${context}-${index}`; // use context and index as query value

			if (currentParams.tabs != queryValue) currentParams.tabs = queryValue;
			else return;

			const { tabs, ...other } = currentParams;

			this.$router.push({ query: { tabs: currentParams.tabs, ...other } });
		},
		/**
		 * to use on mounted of the component who call the mixin
		 * to initiate tabs state
		 * @param  {...string} args // list of context tabs
		 */
		initTabs(...args) {
			/**
			 * init tabs query if not setted
			 * else retrieve query data to set tabs value
			 */
			if (!this.$route.query.tabs) {
				// use activeTabs as default value if args not provided

				if (args.length > 0) args.forEach((arg) => (this.tabs[arg] = 0));
			} else this.handleTabsQuery();
		},
		handleTabsQuery() {
			const query = Array.isArray(this.$route.query.tabs) ? this.$route.query.tabs : [this.$route.query.tabs];
			query.forEach((value) => {
				const data = value.split('-');
				this.tabs[data[0]] = parseInt(data[1]);
			});
		}
	}
};
