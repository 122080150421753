var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("div", { staticClass: "animated fadeIn" }, [
        _vm.$screen.width <= 576
          ? _c("div", { staticClass: "container-mobile" }, [
              _c("div", { staticClass: "my-expense-mobile" }, [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "h1",
                    {
                      staticClass: "main-page-title",
                      class: { "is-pwa": _vm.$isPwa() },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(6, "My expenses")) +
                          "\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
                _vm.project.useExpense
                  ? _c(
                      "div",
                      {
                        staticClass: "my-expense-actions",
                        class: { "pt-16": _vm.$isPwa() },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-start title-add mt-2",
                          },
                          [_vm._v(_vm._s(_vm.FormMSG(11, "Add Expense")))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "d-flex justify-content-center wapper",
                          },
                          [
                            _vm.project.useExpenseTravel
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "btn-action gap-right",
                                    attrs: {
                                      to: "/myexpenses/1/n/2",
                                      tag: "div",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "svgs/mileage.svg",
                                        alt: "Mileage",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(12, "Mileage"))
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.project.useExpenseTicket
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "btn-action gap-right",
                                    attrs: {
                                      to: "/myexpenses/2/n/0",
                                      tag: "div",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "svgs/expense.svg",
                                        alt: "Ticket",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.FormMSG(13, "Ticket"))),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.isFilmSingle &&
                            _vm.project.useExpenseInvoice &&
                            _vm.useExpenseInvoice
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "btn-action",
                                    class: `${
                                      _vm.project.useOcr ? "gap-right" : ""
                                    }`,
                                    attrs: {
                                      to: "/myexpenses/3/n/1",
                                      tag: "div",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "svgs/invoice.svg",
                                        alt: "Invoice",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(14, "Invoice"))
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "form" },
          [
            _vm.$screen.width >= 576
              ? _c(
                  "b-row",
                  { attrs: { "align-h": "between" } },
                  [
                    _c("b-col", { attrs: { sm: "4" } }, [
                      _c("h1", [_vm._v(_vm._s(_vm.FormMSG(6, "My expenses")))]),
                    ]),
                    _c(
                      "b-col",
                      {
                        staticClass: "d-flex justify-content-end pr-0",
                        attrs: { sm: "8" },
                      },
                      [
                        _vm.project.useExpense
                          ? _c(
                              "b-button-group",
                              [
                                _c(
                                  "b-dropdown",
                                  {
                                    staticClass:
                                      "w-150-px mt-2 mb-3 btn-transparent",
                                    attrs: {
                                      "no-caret": "",
                                      right: "",
                                      boundary: "window",
                                      variant: "primary",
                                      id: "dropdown-options",
                                      size: "md",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "button-content",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-center align-items-center",
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon("Plus"),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        size: 18,
                                                        "stroke-width": 2.5,
                                                      },
                                                    }
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "pl-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              51546,
                                                              "New"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2739971781
                                    ),
                                  },
                                  [
                                    _vm.project.useExpenseTicket
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openExpenseModal(
                                                  0,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    2335332,
                                                    "Expense receipt"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.project.useExpenseTravel
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openExpenseModal(
                                                  2,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    187579,
                                                    "Mileage Form"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.isFilmSingle &&
                                    _vm.project.useExpenseInvoice &&
                                    _vm.useExpenseInvoice
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openExpenseModal(
                                                  1,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(3, "Invoice")
                                                ) +
                                                "\n\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.Expenses
              ? _c("b-row", [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column w-100" },
                    [
                      _c(
                        "b-card",
                        {
                          class: `${
                            _vm.$screen.width <= 576 ? "my-project" : ""
                          }`,
                          attrs: { "no-body": "" },
                        },
                        [
                          _c(
                            "b-tabs",
                            {
                              on: {
                                "activate-tab": (n, p, e) =>
                                  _vm.onTabClicked(n),
                              },
                              model: {
                                value: _vm.tabs.activeTabs,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tabs, "activeTabs", $$v)
                                },
                                expression: "tabs.activeTabs",
                              },
                            },
                            [
                              _vm.canSeeApprove
                                ? _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        title: _vm.FormMSG(1111, "Approve"),
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pr-1 pl-1 w-100" },
                                        [
                                          _vm.$screen.width >= 992
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pb-2 d-flex justify-content-end",
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "w-170-px d-flex justify-content-center align-items-center",
                                                      attrs: {
                                                        size: "md",
                                                        variant:
                                                          "blue-sapphire",
                                                        disabled:
                                                          _vm.isReviewModeActive(),
                                                        block: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.reviewModeApprove,
                                                      },
                                                    },
                                                    [
                                                      _c("b-spinner", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.watingSubmitReviewModeApprove,
                                                            expression:
                                                              "watingSubmitReviewModeApprove",
                                                          },
                                                        ],
                                                        staticClass: "mr-2",
                                                        attrs: { small: "" },
                                                      }),
                                                      !_vm.watingSubmitReviewModeApprove
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              "View"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                size: 20,
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-top": "2px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                591,
                                                                "Review mode"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("ValidateExpenses", {
                                            ref: "validate-expenses",
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    lazy: "",
                                    title: _vm.tabOneCaption(),
                                  },
                                },
                                [
                                  _vm.$screen.width <= 576
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "animated fadeIn pr-1 pl-1",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "container-mobile" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "my-expense-mobile",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "my-expense-status-actions p-1",
                                                    },
                                                    [
                                                      _vm.notSubmittedState &&
                                                      _vm.notSubmittedCopyIds
                                                        .length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "check-actions cursor-pointer",
                                                              on: {
                                                                click:
                                                                  _vm.switchCheckAll,
                                                              },
                                                            },
                                                            [
                                                              _vm.checkAll
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex text-left",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              45,
                                                                              "All"
                                                                            )
                                                                          ) +
                                                                          "  \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          "CheckSquare"
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              size: 16,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              46,
                                                                              "None"
                                                                            )
                                                                          ) +
                                                                          " \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          "Square"
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              size: 16,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _vm.expenses.length > 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "content pb-3",
                                                        },
                                                        _vm._l(
                                                          _vm.expenses,
                                                          function (expense) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: expense.id,
                                                                staticClass:
                                                                  "card-expense",
                                                                attrs: {
                                                                  id: `${
                                                                    _vm.notSubmittedState &&
                                                                    parseInt(
                                                                      expense.validated,
                                                                      10
                                                                    ) === 0
                                                                      ? _vm.generateTextId(
                                                                          "card-expense-" +
                                                                            expense.id
                                                                        )
                                                                      : ""
                                                                  }`,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "wrapper",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "resume",
                                                                      },
                                                                      [
                                                                        _vm.notSubmittedState &&
                                                                        parseInt(
                                                                          expense.validated,
                                                                          10
                                                                        ) === 0
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "first-col",
                                                                              },
                                                                              [
                                                                                +expense.amountTotal !==
                                                                                0
                                                                                  ? _c(
                                                                                      "b-form-checkbox",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            size: "lg",
                                                                                            value:
                                                                                              expense.id,
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.setSelectedItem(
                                                                                                $event,
                                                                                                expense,
                                                                                                _vm.generateTextId(
                                                                                                  "card-expense-" +
                                                                                                    expense.id
                                                                                                )
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm.notSubmittedIds,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.notSubmittedIds =
                                                                                                  $$v
                                                                                              },
                                                                                            expression:
                                                                                              "notSubmittedIds",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "second-col",
                                                                            style: `width: ${
                                                                              _vm.notSubmittedState &&
                                                                              parseInt(
                                                                                expense.validated,
                                                                                10
                                                                              ) ===
                                                                                0
                                                                                ? "56.5%;"
                                                                                : "67.5%;"
                                                                            }`,
                                                                          },
                                                                          [
                                                                            _vm.showExpenseTitle(
                                                                              expense
                                                                            )
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "description",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          expense.description
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "title",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "#" +
                                                                                    _vm._s(
                                                                                      expense.id
                                                                                    )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "amount",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.rendAmountTotal(
                                                                                        expense.amountTotal
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "date",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.dateToLiteral(
                                                                                        expense.date
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            expense.type !==
                                                                            2
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "carbon",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "icon",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            "Sprout"
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "#47C7BF",
                                                                                                size: 14,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "label",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                            _vm._s(
                                                                                              _vm.rendCo2(
                                                                                                expense.kgCoTwo
                                                                                              )
                                                                                            )
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              " CO"
                                                                                            ),
                                                                                            _c(
                                                                                              "sub",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "2"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mileage-resume",
                                                                                  },
                                                                                  [
                                                                                    expense.fromLocation &&
                                                                                    expense.toLocation
                                                                                      ? _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "details pt-1",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "first-col",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "icon-rotate",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      _vm.getLucideIcon(
                                                                                                        "ArrowLeftRight"
                                                                                                      ),
                                                                                                      {
                                                                                                        tag: "component",
                                                                                                        attrs:
                                                                                                          {
                                                                                                            color:
                                                                                                              "#47C7BF",
                                                                                                            size: 16,
                                                                                                          },
                                                                                                      }
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "second-col",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        expense.fromLocation
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        expense.toLocation
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "details",
                                                                                        class: `${
                                                                                          !expense.km
                                                                                            ? ""
                                                                                            : "pt-2"
                                                                                        }`,
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "first-col",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "icon",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  _vm.getLucideIcon(
                                                                                                    "Sprout"
                                                                                                  ),
                                                                                                  {
                                                                                                    tag: "component",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "#47C7BF",
                                                                                                        size: !expense.km
                                                                                                          ? 14
                                                                                                          : 16,
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "second-col",
                                                                                            class: `${
                                                                                              !expense.km
                                                                                                ? "spl-4"
                                                                                                : ""
                                                                                            }`,
                                                                                          },
                                                                                          [
                                                                                            expense.km
                                                                                              ? _c(
                                                                                                  "div",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                        _vm._s(
                                                                                                          _vm.recalculateDistanceByUnit(
                                                                                                            expense.km
                                                                                                          )
                                                                                                        ) +
                                                                                                        " " +
                                                                                                        _vm._s(
                                                                                                          _vm.distanceUnit
                                                                                                        ) +
                                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                style: `margin-bottom: ${
                                                                                                  expense.km
                                                                                                    ? "0"
                                                                                                    : "-1px"
                                                                                                }`,
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                    _vm._s(
                                                                                                      _vm.rendCo2(
                                                                                                        expense.kgCoTwo
                                                                                                      )
                                                                                                    ) +
                                                                                                    " CO"
                                                                                                ),
                                                                                                _c(
                                                                                                  "sub",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "2"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "third-col",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "status-wrapper",
                                                                              },
                                                                              [
                                                                                !_vm.isFilmSingle
                                                                                  ? _c(
                                                                                      "div",
                                                                                      {
                                                                                        class: `status ${expense.statusClass}`,
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                            _vm._s(
                                                                                              expense.validatedStatus
                                                                                            ) +
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    class: `${
                                                                                      !_vm.isFilmSingle
                                                                                        ? "type mt-2"
                                                                                        : "type"
                                                                                    }`,
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "icon",
                                                                                      },
                                                                                      [
                                                                                        expense.type ===
                                                                                        0
                                                                                          ? _c(
                                                                                              _vm.getLucideIcon(
                                                                                                "Ticket"
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "#5B6164D6",
                                                                                                    size: 16,
                                                                                                  },
                                                                                              }
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        expense.type ===
                                                                                        1
                                                                                          ? _c(
                                                                                              _vm.getLucideIcon(
                                                                                                "FileText"
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "#5B6164D6",
                                                                                                    size: 14,
                                                                                                  },
                                                                                              }
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        expense.type ===
                                                                                        2
                                                                                          ? _c(
                                                                                              _vm.getLucideIcon(
                                                                                                "Car"
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "#5B6164D6",
                                                                                                    size: 16,
                                                                                                  },
                                                                                              }
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "label",
                                                                                        style: `${
                                                                                          expense.type ===
                                                                                          2
                                                                                            ? "margin-top: 2px;"
                                                                                            : ""
                                                                                        }`,
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            expense.typeString
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "footer",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "actions",
                                                                      },
                                                                      [
                                                                        _vm.notSubmittedState &&
                                                                        expense
                                                                          .images
                                                                          .length >
                                                                          0
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "first-col",
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.showPicture(
                                                                                        expense.images,
                                                                                        expense
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "icon",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      _vm.getLucideIcon(
                                                                                        "FileImage"
                                                                                      ),
                                                                                      {
                                                                                        tag: "component",
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "#06263E",
                                                                                            size: 16,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "label",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "margin-top":
                                                                                          "1px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          28,
                                                                                          "See Receipt"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "second-col",
                                                                            style: `width: ${
                                                                              (_vm.notSubmittedState &&
                                                                                expense
                                                                                  .images
                                                                                  .length ===
                                                                                  0) ||
                                                                              _vm.submittedState
                                                                                ? "100%;"
                                                                                : "60%;"
                                                                            }`,
                                                                          },
                                                                          [
                                                                            _vm.notSubmittedState
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "cursor-pointer d-flex flex-row pr-3",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.editExpenseMobile(
                                                                                            expense
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "icon",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            "Edit"
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "#06263E",
                                                                                                size: 16,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "label",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.FormMSG(
                                                                                              29,
                                                                                              "Edit"
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm.submittedState
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "cursor-pointer d-flex flex-row",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.viewExpenseDatailMobile(
                                                                                            expense
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "icon",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            "Eye"
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "#06263E",
                                                                                                size: 16,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "label",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.FormMSG(
                                                                                              33,
                                                                                              "View"
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm.notSubmittedState
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "cursor-pointer d-flex flex-row",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.deleteExpenseMobile(
                                                                                            expense.id
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "icon",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            "Trash"
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "#06263E",
                                                                                                size: 16,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "label",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.FormMSG(
                                                                                              32,
                                                                                              "Delete"
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "empty-data",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  18,
                                                                  "No expense found"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                  _vm.notSubmittedState &&
                                                  _vm.notSubmittedIds.length > 0
                                                    ? _c(
                                                        "b-row",
                                                        {
                                                          staticClass:
                                                            "footer-fixed",
                                                        },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    block: "",
                                                                    size: "lg",
                                                                    variant:
                                                                      "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.handleSendExpensesForValidationMobile,
                                                                  },
                                                                },
                                                                [
                                                                  !_vm.isFilmSingle
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                34,
                                                                                "Send Expenses for Validation"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                36,
                                                                                "Create expenses sheet"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "b-card-text",
                                        [
                                          _vm.notSubmitedExpenses.length > 0
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "b-row",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center pb-0 pt-3",
                                                      attrs: {
                                                        "align-h": "between",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass: "pl-4",
                                                          attrs: { md: "6" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              staticClass:
                                                                "mr-3",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "fw-400",
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "13px",
                                                                    color:
                                                                      "rgba(6, 38, 62, 0.65)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t*" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          8,
                                                                          "Click on a row to view more informations"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm.isActiveSelectAll &&
                                                                        !_vm.isSelectAll,
                                                                      expression:
                                                                        "isActiveSelectAll && !isSelectAll",
                                                                    },
                                                                  ],
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "pj-cb",
                                                                      attrs: {
                                                                        size: "lg",
                                                                        id: "select-all-item",
                                                                        value: true,
                                                                        "unchecked-value": false,
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.setIsSelectAll,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.isSelectAll,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.isSelectAll =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "isSelectAll",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-top":
                                                                                "1px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                51,
                                                                                "Select all"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm.isActiveSelectAll &&
                                                                        _vm.isSelectAll,
                                                                      expression:
                                                                        "isActiveSelectAll && isSelectAll",
                                                                    },
                                                                  ],
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "pj-cb",
                                                                      attrs: {
                                                                        value: true,
                                                                        "unchecked-value": false,
                                                                        size: "lg",
                                                                        id: "select-all-item-none",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.setIsSelectAll,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.isSelectAll,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.isSelectAll =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "isSelectAll",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-top":
                                                                                "1px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                50,
                                                                                "Unselect All"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass:
                                                            "text-right pb-3",
                                                          attrs: { md: "6" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                disabled:
                                                                  _vm
                                                                    .itemsSelected
                                                                    .length !==
                                                                  0
                                                                    ? false
                                                                    : true,
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handSendAllExpensesValidation,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    !_vm.isFilmSingle
                                                                      ? _vm.FormMSG(
                                                                          7,
                                                                          "Send expenses for validation"
                                                                        )
                                                                      : _vm.FormMSG(
                                                                          63,
                                                                          "Create expense sheet"
                                                                        )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("b-table", {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                    },
                                                    attrs: {
                                                      id: "table-clickable",
                                                      hover: _vm.hover,
                                                      responsive: "sm",
                                                      items:
                                                        _vm.notSubmitedExpenses,
                                                      fields:
                                                        _vm.notSubmittedTableFields,
                                                      "current-page":
                                                        _vm.notSubmitedYetTableCurrentPage,
                                                      "per-page": _vm.perPage,
                                                      "sticky-header": "700px",
                                                      bordered: "",
                                                      small: "",
                                                      "head-variant": _vm.hv,
                                                      "empty-text": _vm.FormMSG(
                                                        163,
                                                        "No expense found"
                                                      ),
                                                      "show-empty": "",
                                                    },
                                                    on: {
                                                      "row-clicked":
                                                        _vm.openEditExpense,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "cell(select)",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .validated !==
                                                                          _vm
                                                                            .VALIDATION_TYPE
                                                                            .REFUSED_LEVEL_2 &&
                                                                        data
                                                                          .item
                                                                          .validated !==
                                                                          _vm
                                                                            .VALIDATION_TYPE
                                                                            .REFUSED_LEVEL_1,
                                                                      expression:
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tdata.item.validated !== VALIDATION_TYPE.REFUSED_LEVEL_2 &&\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tdata.item.validated !== VALIDATION_TYPE.REFUSED_LEVEL_1\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                                                    },
                                                                  ],
                                                                },
                                                                [
                                                                  +data.item
                                                                    .amountTotal >
                                                                  0
                                                                    ? _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          staticClass:
                                                                            "pj-cb pb-1",
                                                                          attrs:
                                                                            {
                                                                              id: `expense-efc-selected-${
                                                                                data
                                                                                  .item
                                                                                  .id +
                                                                                Math.random()
                                                                              }`,
                                                                              name: `expEfcSelected${data.item.id}`,
                                                                              size: "lg",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleChangeExpSelected(
                                                                                  $event,
                                                                                  data.item
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .isSelected,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "isSelected",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.isSelected",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(validatedStatus)",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "wrap-status",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      class: `status ${data.item.statusClass}`,
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "0.7rem",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            data
                                                                              .item
                                                                              .validatedStatus
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(encoder)",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "wrap-status",
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .encoder
                                                                          .name
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .encoder
                                                                            .firstName
                                                                        )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(pict)",
                                                          fn: function (data) {
                                                            return [
                                                              data.item.images
                                                                .length > 0
                                                                ? _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "btn bg-transparent border-0",
                                                                      attrs: {
                                                                        variant:
                                                                          "success",
                                                                        size: "sm",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.showPicture(
                                                                              data
                                                                                .item
                                                                                .images,
                                                                              data.item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .PICTURE
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .PICTURE
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(rem)",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .TRASH.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .TRASH
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        $event.stopPropagation()
                                                                        return _vm.handleDeleteExpense(
                                                                          data.item
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      78949529
                                                    ),
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.notSubmitedExpenses.length < 1
                                            ? _c(
                                                "div",
                                                { staticClass: "empty-state" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          99,
                                                          "You have no expense items yet"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.notSubmitedExpenses.length > 0
                                            ? _c(
                                                "b-row",
                                                [
                                                  _c(
                                                    "b-col",
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "w-138-px",
                                                          attrs: {
                                                            variant:
                                                              "custom-outline-gray",
                                                            disabled:
                                                              _vm.itemsSelected
                                                                .length === 0,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleDeleteSelected,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  590,
                                                                  "Delete"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    lazy: "",
                                    title: _vm.tabTwoCaption(),
                                  },
                                },
                                [
                                  _vm.$screen.width <= 576
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "animated fadeIn pr-1 pl-1",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "container-mobile" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "my-expense-mobile",
                                                },
                                                [
                                                  _vm.Expenses.submited &&
                                                  _vm.Expenses.submited.length >
                                                    0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "content pb-3",
                                                        },
                                                        _vm._l(
                                                          _vm.Expenses.submited,
                                                          function (expense) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: expense.id,
                                                                staticClass:
                                                                  "card-expense",
                                                                attrs: {
                                                                  id: `${
                                                                    parseInt(
                                                                      expense.validated,
                                                                      10
                                                                    ) === 0
                                                                      ? _vm.generateTextId(
                                                                          "card-expense-" +
                                                                            expense.id
                                                                        )
                                                                      : ""
                                                                  }`,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "wrapper",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "resume",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "second-col",
                                                                            style: `width: ${
                                                                              parseInt(
                                                                                expense.validated,
                                                                                10
                                                                              ) ===
                                                                              0
                                                                                ? "56.5%;"
                                                                                : "67.5%;"
                                                                            }`,
                                                                          },
                                                                          [
                                                                            _vm.showExpenseTitle(
                                                                              expense
                                                                            )
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "description",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          expense.description
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "title",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "#" +
                                                                                    _vm._s(
                                                                                      expense.id
                                                                                    )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "amount",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.rendAmountTotal(
                                                                                        expense.amountTotal
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "date",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.dateToLiteral(
                                                                                        expense.date
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            expense.type !==
                                                                            2
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "carbon",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "icon",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            "Sprout"
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "#47C7BF",
                                                                                                size: 14,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "label",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                            _vm._s(
                                                                                              _vm.rendCo2(
                                                                                                expense.kgCoTwo
                                                                                              )
                                                                                            )
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              " CO"
                                                                                            ),
                                                                                            _c(
                                                                                              "sub",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "2"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mileage-resume",
                                                                                  },
                                                                                  [
                                                                                    expense.fromLocation &&
                                                                                    expense.toLocation
                                                                                      ? _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "details pt-1",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "first-col",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "icon-rotate",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      _vm.getLucideIcon(
                                                                                                        "ArrowLeftRight"
                                                                                                      ),
                                                                                                      {
                                                                                                        tag: "component",
                                                                                                        attrs:
                                                                                                          {
                                                                                                            color:
                                                                                                              "#47C7BF",
                                                                                                            size: 16,
                                                                                                          },
                                                                                                      }
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "second-col",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        expense.fromLocation
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        expense.toLocation
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "details",
                                                                                        class: `${
                                                                                          !expense.km
                                                                                            ? ""
                                                                                            : "pt-2"
                                                                                        }`,
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "first-col",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "icon",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  _vm.getLucideIcon(
                                                                                                    "Sprout"
                                                                                                  ),
                                                                                                  {
                                                                                                    tag: "component",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "#47C7BF",
                                                                                                        size: !expense.km
                                                                                                          ? 14
                                                                                                          : 16,
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "second-col",
                                                                                            class: `${
                                                                                              !expense.km
                                                                                                ? "spl-4"
                                                                                                : ""
                                                                                            }`,
                                                                                          },
                                                                                          [
                                                                                            expense.km
                                                                                              ? _c(
                                                                                                  "div",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                        _vm._s(
                                                                                                          _vm.recalculateDistanceByUnit(
                                                                                                            expense.km
                                                                                                          )
                                                                                                        ) +
                                                                                                        " " +
                                                                                                        _vm._s(
                                                                                                          _vm.distanceUnit
                                                                                                        ) +
                                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                style: `margin-bottom: ${
                                                                                                  expense.km
                                                                                                    ? "0"
                                                                                                    : "-1px"
                                                                                                }`,
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                    _vm._s(
                                                                                                      _vm.rendCo2(
                                                                                                        expense.kgCoTwo
                                                                                                      )
                                                                                                    ) +
                                                                                                    " CO"
                                                                                                ),
                                                                                                _c(
                                                                                                  "sub",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "2"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "third-col",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "status-wrapper",
                                                                              },
                                                                              [
                                                                                !_vm.isFilmSingle
                                                                                  ? _c(
                                                                                      "div",
                                                                                      {
                                                                                        class: `status ${expense.statusClass}`,
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                            _vm._s(
                                                                                              expense.validatedStatus
                                                                                            ) +
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    class: `${
                                                                                      !_vm.isFilmSingle
                                                                                        ? "type mt-2"
                                                                                        : "type"
                                                                                    }`,
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "icon",
                                                                                      },
                                                                                      [
                                                                                        expense.type ===
                                                                                        0
                                                                                          ? _c(
                                                                                              _vm.getLucideIcon(
                                                                                                "Ticket"
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "#5B6164D6",
                                                                                                    size: 16,
                                                                                                  },
                                                                                              }
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        expense.type ===
                                                                                        1
                                                                                          ? _c(
                                                                                              _vm.getLucideIcon(
                                                                                                "FileText"
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "#5B6164D6",
                                                                                                    size: 14,
                                                                                                  },
                                                                                              }
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        expense.type ===
                                                                                        2
                                                                                          ? _c(
                                                                                              _vm.getLucideIcon(
                                                                                                "Car"
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "#5B6164D6",
                                                                                                    size: 16,
                                                                                                  },
                                                                                              }
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "label",
                                                                                        style: `${
                                                                                          expense.type ===
                                                                                          2
                                                                                            ? "margin-top: 2px;"
                                                                                            : ""
                                                                                        }`,
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            expense.typeString
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "footer",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "actions",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "second-col",
                                                                            style: `width: ${
                                                                              _vm.submittedState
                                                                                ? "100%;"
                                                                                : "60%;"
                                                                            }`,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "cursor-pointer d-flex flex-row",
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.viewExpenseDatailMobile(
                                                                                        expense
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "icon",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      _vm.getLucideIcon(
                                                                                        "Eye"
                                                                                      ),
                                                                                      {
                                                                                        tag: "component",
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "#06263E",
                                                                                            size: 16,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "label",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          33,
                                                                                          "View"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "empty-data",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  18,
                                                                  "No expense found"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                  _vm.notSubmittedState &&
                                                  _vm.notSubmittedIds.length > 0
                                                    ? _c(
                                                        "b-row",
                                                        {
                                                          staticClass:
                                                            "footer-fixed",
                                                        },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    block: "",
                                                                    size: "lg",
                                                                    variant:
                                                                      "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.handleSendExpensesForValidationMobile,
                                                                  },
                                                                },
                                                                [
                                                                  !_vm.isFilmSingle
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                34,
                                                                                "Send Expenses for Validation"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                36,
                                                                                "Create expenses sheet"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "b-card-text",
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "pb-3",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          placeholder:
                                                            _vm.FormMSG(
                                                              31,
                                                              "Type to Search"
                                                            ),
                                                        },
                                                        model: {
                                                          value: _vm.filter,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.filter = $$v
                                                          },
                                                          expression: "filter",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        {
                                                          staticClass:
                                                            "cursor-pointer",
                                                        },
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            {
                                                              staticClass:
                                                                "btn-search",
                                                            },
                                                            [
                                                              _vm.filter
                                                                .length === 0
                                                                ? _c(
                                                                    _vm.getLucideIcon(
                                                                      _vm.ICONS
                                                                        .SEARCH
                                                                        .name
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      staticClass:
                                                                        "icon",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .SEARCH
                                                                            .color,
                                                                        size: 16,
                                                                        "stroke-width": 2.5,
                                                                      },
                                                                    }
                                                                  )
                                                                : _c(
                                                                    _vm.getLucideIcon(
                                                                      _vm.ICONS
                                                                        .X.name
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      staticClass:
                                                                        "icon",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .X
                                                                            .color,
                                                                        size: 16,
                                                                        "stroke-width": 2.5,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.filter =
                                                                              ""
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("b-col", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row justify-content-end",
                                                  },
                                                  [
                                                    _vm.$screen.width >= 992
                                                      ? _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "w-200-px d-flex justify-content-center align-items-center",
                                                            staticStyle: {
                                                              "margin-top":
                                                                "-1px",
                                                            },
                                                            attrs: {
                                                              size: "md",
                                                              variant:
                                                                "custom-outline-primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.Expenses
                                                                  .submited
                                                                  .length === 0,
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.reviewModePending,
                                                            },
                                                          },
                                                          [
                                                            _c("b-spinner", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.watingSubmitReviewModePending,
                                                                  expression:
                                                                    "watingSubmitReviewModePending",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            }),
                                                            !_vm.watingSubmitReviewModePending
                                                              ? _c(
                                                                  _vm.getLucideIcon(
                                                                    "View"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      size: 20,
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "2px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    this.FormMSG(
                                                                      4565,
                                                                      "Review mode"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("b-table", {
                                            attrs: {
                                              id: "table-clickable",
                                              hover: _vm.hover,
                                              responsive: "sm",
                                              filter: _vm.filter,
                                              items: _vm.Expenses.submited,
                                              fields: _vm.submittedTableFields,
                                              "current-page": _vm.currentPage,
                                              "per-page": 0,
                                              bordered: "",
                                              small: "",
                                              "sticky-header": "700px",
                                              "head-variant": _vm.hv,
                                              "empty-text": _vm.FormMSG(
                                                163,
                                                "No expense found"
                                              ),
                                              "show-empty": "",
                                            },
                                            on: {
                                              "row-clicked": _vm.rowClicked,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "cell(validatedStatus)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "wrap-status",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              class: `status ${data.item.statusClass}`,
                                                              staticStyle: {
                                                                "font-size":
                                                                  "0.7rem",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    data.item
                                                                      .validatedStatus
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(encoder)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "wrap-status",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                data.item
                                                                  .encoder.name
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  data.item
                                                                    .encoder
                                                                    .firstName
                                                                )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1249484253
                                            ),
                                          }),
                                          _c(
                                            "expense-sheet-carousel-view-modal",
                                            {
                                              ref: "expense-sheet-carousel-view-modal-pending",
                                              attrs: {
                                                items: _vm.Expenses.submited,
                                                "is-my-expense": true,
                                                "show-encoder-detail": "",
                                              },
                                              on: {
                                                "expense-sheet-carousel-view-modal:closed":
                                                  _vm.onExpenseSheetCarouselViewModalForPendingClosed,
                                                "expense-sheet-carousel-view-modal:reload":
                                                  _vm.onExpenseSheetCarouselViewModalForPendingReloaded,
                                              },
                                              model: {
                                                value:
                                                  _vm.isExpenseSheetCarouselViewForPendingOpened,
                                                callback: function ($$v) {
                                                  _vm.isExpenseSheetCarouselViewForPendingOpened =
                                                    $$v
                                                },
                                                expression:
                                                  "isExpenseSheetCarouselViewForPendingOpened",
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                              !_vm.isFilmSingle
                                ? _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        lazy: "",
                                        title: _vm.FormMSG(35265, "Approved"),
                                      },
                                    },
                                    [
                                      _vm.$screen.width <= 576
                                        ? _c(
                                            "div",
                                            { staticClass: "animated fadeIn" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "container-mobile",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "my-expense-mobile",
                                                    },
                                                    [
                                                      _vm.Expenses.approved
                                                        .length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "content pb-3",
                                                            },
                                                            _vm._l(
                                                              _vm.Expenses
                                                                .approved,
                                                              function (
                                                                expense
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: expense.id,
                                                                    staticClass:
                                                                      "card-expense",
                                                                    attrs: {
                                                                      id: `${
                                                                        parseInt(
                                                                          expense.validated,
                                                                          10
                                                                        ) === 0
                                                                          ? _vm.generateTextId(
                                                                              "card-expense-" +
                                                                                expense.id
                                                                            )
                                                                          : ""
                                                                      }`,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "wrapper",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "resume",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "second-col",
                                                                                style: `width: ${
                                                                                  parseInt(
                                                                                    expense.validated,
                                                                                    10
                                                                                  ) ===
                                                                                  0
                                                                                    ? "56.5%;"
                                                                                    : "67.5%;"
                                                                                }`,
                                                                              },
                                                                              [
                                                                                _vm.showExpenseTitle(
                                                                                  expense
                                                                                )
                                                                                  ? _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "description",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                            _vm._s(
                                                                                              expense.description
                                                                                            ) +
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "title",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "#" +
                                                                                        _vm._s(
                                                                                          expense.id
                                                                                        )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "amount",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          _vm.rendAmountTotal(
                                                                                            expense.amountTotal
                                                                                          )
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "date",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          _vm.dateToLiteral(
                                                                                            expense.date
                                                                                          )
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                expense.type !==
                                                                                2
                                                                                  ? _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "carbon",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "icon",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              _vm.getLucideIcon(
                                                                                                "Sprout"
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "#47C7BF",
                                                                                                    size: 14,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "label",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                _vm._s(
                                                                                                  _vm.rendCo2(
                                                                                                    expense.kgCoTwo
                                                                                                  )
                                                                                                )
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " CO"
                                                                                                ),
                                                                                                _c(
                                                                                                  "sub",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "2"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mileage-resume",
                                                                                      },
                                                                                      [
                                                                                        expense.fromLocation &&
                                                                                        expense.toLocation
                                                                                          ? _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "details pt-1",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "first-col",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "icon-rotate",
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          _vm.getLucideIcon(
                                                                                                            "ArrowLeftRight"
                                                                                                          ),
                                                                                                          {
                                                                                                            tag: "component",
                                                                                                            attrs:
                                                                                                              {
                                                                                                                color:
                                                                                                                  "#47C7BF",
                                                                                                                size: 16,
                                                                                                              },
                                                                                                          }
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "second-col",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            expense.fromLocation
                                                                                                          )
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            expense.toLocation
                                                                                                          )
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "details",
                                                                                            class: `${
                                                                                              !expense.km
                                                                                                ? ""
                                                                                                : "pt-2"
                                                                                            }`,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "first-col",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "icon",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      _vm.getLucideIcon(
                                                                                                        "Sprout"
                                                                                                      ),
                                                                                                      {
                                                                                                        tag: "component",
                                                                                                        attrs:
                                                                                                          {
                                                                                                            color:
                                                                                                              "#47C7BF",
                                                                                                            size: !expense.km
                                                                                                              ? 14
                                                                                                              : 16,
                                                                                                          },
                                                                                                      }
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "second-col",
                                                                                                class: `${
                                                                                                  !expense.km
                                                                                                    ? "spl-4"
                                                                                                    : ""
                                                                                                }`,
                                                                                              },
                                                                                              [
                                                                                                expense.km
                                                                                                  ? _c(
                                                                                                      "div",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                            _vm._s(
                                                                                                              _vm.recalculateDistanceByUnit(
                                                                                                                expense.km
                                                                                                              )
                                                                                                            ) +
                                                                                                            " " +
                                                                                                            _vm._s(
                                                                                                              _vm.distanceUnit
                                                                                                            ) +
                                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                        ),
                                                                                                      ]
                                                                                                    )
                                                                                                  : _vm._e(),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    style: `margin-bottom: ${
                                                                                                      expense.km
                                                                                                        ? "0"
                                                                                                        : "-1px"
                                                                                                    }`,
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                        _vm._s(
                                                                                                          _vm.rendCo2(
                                                                                                            expense.kgCoTwo
                                                                                                          )
                                                                                                        ) +
                                                                                                        " CO"
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "sub",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "2"
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "third-col",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "status-wrapper",
                                                                                  },
                                                                                  [
                                                                                    !_vm.isFilmSingle
                                                                                      ? _c(
                                                                                          "div",
                                                                                          {
                                                                                            class: `status ${expense.statusClass}`,
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                _vm._s(
                                                                                                  expense.validatedStatus
                                                                                                ) +
                                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        class: `${
                                                                                          !_vm.isFilmSingle
                                                                                            ? "type mt-2"
                                                                                            : "type"
                                                                                        }`,
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "icon",
                                                                                          },
                                                                                          [
                                                                                            expense.type ===
                                                                                            0
                                                                                              ? _c(
                                                                                                  _vm.getLucideIcon(
                                                                                                    "Ticket"
                                                                                                  ),
                                                                                                  {
                                                                                                    tag: "component",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "#5B6164D6",
                                                                                                        size: 16,
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            expense.type ===
                                                                                            1
                                                                                              ? _c(
                                                                                                  _vm.getLucideIcon(
                                                                                                    "FileText"
                                                                                                  ),
                                                                                                  {
                                                                                                    tag: "component",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "#5B6164D6",
                                                                                                        size: 14,
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            expense.type ===
                                                                                            2
                                                                                              ? _c(
                                                                                                  _vm.getLucideIcon(
                                                                                                    "Car"
                                                                                                  ),
                                                                                                  {
                                                                                                    tag: "component",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "#5B6164D6",
                                                                                                        size: 16,
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "label",
                                                                                            style: `${
                                                                                              expense.type ===
                                                                                              2
                                                                                                ? "margin-top: 2px;"
                                                                                                : ""
                                                                                            }`,
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                expense.typeString
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "footer",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "actions",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "second-col",
                                                                                style: `width: ${
                                                                                  _vm.submittedState
                                                                                    ? "100%;"
                                                                                    : "60%;"
                                                                                }`,
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "cursor-pointer d-flex flex-row",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.viewExpenseDatailMobile(
                                                                                            expense
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "icon",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            "Eye"
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "#06263E",
                                                                                                size: 16,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "label",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.FormMSG(
                                                                                              33,
                                                                                              "View"
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "empty-data",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      18,
                                                                      "No expense found"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                      _vm.notSubmittedState &&
                                                      _vm.notSubmittedIds
                                                        .length > 0
                                                        ? _c(
                                                            "b-row",
                                                            {
                                                              staticClass:
                                                                "footer-fixed",
                                                            },
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "mb-2",
                                                                      attrs: {
                                                                        block:
                                                                          "",
                                                                        size: "lg",
                                                                        variant:
                                                                          "primary",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.handleSendExpensesForValidationMobile,
                                                                      },
                                                                    },
                                                                    [
                                                                      !_vm.isFilmSingle
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.FormMSG(
                                                                                    34,
                                                                                    "Send Expenses for Validation"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.FormMSG(
                                                                                    36,
                                                                                    "Create expenses sheet"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "b-card-text",
                                            [
                                              _c(
                                                "b-row",
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "pb-3",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-input-group",
                                                        [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              type: "text",
                                                              placeholder:
                                                                _vm.FormMSG(
                                                                  31,
                                                                  "Type to Search"
                                                                ),
                                                            },
                                                            model: {
                                                              value: _vm.filter,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.filter =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "filter",
                                                            },
                                                          }),
                                                          _c(
                                                            "b-input-group-append",
                                                            {
                                                              staticClass:
                                                                "cursor-pointer",
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group-text",
                                                                {
                                                                  staticClass:
                                                                    "btn-search",
                                                                },
                                                                [
                                                                  _vm.filter
                                                                    .length ===
                                                                  0
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .SEARCH
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          staticClass:
                                                                            "icon",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .SEARCH
                                                                                  .color,
                                                                              size: 16,
                                                                              "stroke-width": 2.5,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .X
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          staticClass:
                                                                            "icon",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .X
                                                                                  .color,
                                                                              size: 16,
                                                                              "stroke-width": 2.5,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.filter =
                                                                                  ""
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("b-col", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-row justify-content-end",
                                                      },
                                                      [
                                                        _vm.$screen.width >= 992
                                                          ? _c(
                                                              "b-button",
                                                              {
                                                                staticClass:
                                                                  "w-200-px d-flex justify-content-center align-items-center",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "-1px",
                                                                },
                                                                attrs: {
                                                                  size: "md",
                                                                  variant:
                                                                    "custom-outline-primary",
                                                                  block: "",
                                                                  disabled:
                                                                    _vm.Expenses
                                                                      .approved
                                                                      .length ===
                                                                    0,
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.reviewModeApproved,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-spinner",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            _vm.watingSubmitReviewModeApproved,
                                                                          expression:
                                                                            "watingSubmitReviewModeApproved",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                  }
                                                                ),
                                                                !_vm.watingSubmitReviewModeApproved
                                                                  ? _c(
                                                                      _vm.getLucideIcon(
                                                                        "View"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        staticClass:
                                                                          "mr-2",
                                                                        attrs: {
                                                                          size: 20,
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-top":
                                                                          "2px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        this.FormMSG(
                                                                          4566,
                                                                          "Review mode"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("b-table", {
                                                attrs: {
                                                  id: "table-clickable",
                                                  hover: _vm.hover,
                                                  responsive: "sm",
                                                  filter: _vm.filter,
                                                  items: _vm.Expenses.approved,
                                                  fields:
                                                    _vm.submittedTableFields,
                                                  "current-page":
                                                    _vm.currentPage,
                                                  "per-page": 0,
                                                  bordered: "",
                                                  small: "",
                                                  "sticky-header": "700px",
                                                  "head-variant": _vm.hv,
                                                  "empty-text": _vm.FormMSG(
                                                    163,
                                                    "No expense found"
                                                  ),
                                                  "show-empty": "",
                                                },
                                                on: {
                                                  "row-clicked": _vm.rowClicked,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "cell(validatedStatus)",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "wrap-status",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  class: `status ${data.item.statusClass}`,
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "0.7rem",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .validatedStatus
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "cell(encoder)",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "wrap-status",
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    data.item
                                                                      .encoder
                                                                      .name
                                                                  ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .encoder
                                                                        .firstName
                                                                    )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1249484253
                                                ),
                                              }),
                                              _c(
                                                "expense-sheet-carousel-view-modal",
                                                {
                                                  ref: "expense-sheet-carousel-view-modal-approved",
                                                  attrs: {
                                                    items:
                                                      _vm.Expenses.approved,
                                                    "is-my-expense": true,
                                                    "show-encoder-detail": "",
                                                  },
                                                  on: {
                                                    "expense-sheet-carousel-view-modal:closed":
                                                      _vm.onExpenseSheetCarouselViewModalForApprovedClosed,
                                                    "expense-sheet-carousel-view-modal:reload":
                                                      _vm.onExpenseSheetCarouselViewModalForApprovedReloaded,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.isExpenseSheetCarouselViewForApprovedOpened,
                                                    callback: function ($$v) {
                                                      _vm.isExpenseSheetCarouselViewForApprovedOpened =
                                                        $$v
                                                    },
                                                    expression:
                                                      "isExpenseSheetCarouselViewForApprovedOpened",
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("ExpenseService", {
              key: _vm.expenseModalRefreshToken,
              attrs: {
                "expense-type": _vm.newExpenseType,
                "is-new": _vm.isNew,
                "edit-data": _vm.currEditExpense,
              },
              on: {
                "expense-service-main:error": _vm.getError,
                submited: _vm.handleModalSubmited,
                "expense-form-component:close": _vm.onCloseExpenseForm,
              },
              model: {
                value: _vm.isNewExpenseModalOpen,
                callback: function ($$v) {
                  _vm.isNewExpenseModalOpen = $$v
                },
                expression: "isNewExpenseModalOpen",
              },
            }),
            _c("title-expense-modal", {
              attrs: { "reinitialize-value": _vm.reinitializeTitle },
              on: {
                "title-expense-modal:cancel": _vm.onCancelTitleModal,
                "title-expense-modal:add": _vm.onAddTitleModalPC,
              },
              model: {
                value: _vm.isOpenTitleModalPC,
                callback: function ($$v) {
                  _vm.isOpenTitleModalPC = $$v
                },
                expression: "isOpenTitleModalPC",
              },
            }),
            _c("title-expense-modal", {
              attrs: { "reinitialize-value": _vm.reinitializeTitle },
              on: {
                "title-expense-modal:cancel": _vm.onCancelTitleModal,
                "title-expense-modal:add": _vm.onAddTitleModal,
              },
              model: {
                value: _vm.isOpenTitleModal,
                callback: function ($$v) {
                  _vm.isOpenTitleModal = $$v
                },
                expression: "isOpenTitleModal",
              },
            }),
            _c(
              "b-modal",
              {
                attrs: {
                  "hide-header-close": "",
                  "no-close-on-backdrop": "",
                  "no-close-on-esc": "",
                  "ok-only": "",
                  "header-bg-variant": "warning",
                },
                scopedSlots: _vm._u([
                  {
                    key: "modal-title",
                    fn: function () {
                      return [
                        _c(_vm.getLucideIcon("AlertTriangle"), {
                          tag: "component",
                          attrs: { size: 16 },
                        }),
                        _vm._v(" " + _vm._s(_vm.FormMSG(181, "Warning")) + " "),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.showModalWarningItemNumber,
                  callback: function ($$v) {
                    _vm.showModalWarningItemNumber = $$v
                  },
                  expression: "showModalWarningItemNumber",
                },
              },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.msgWarnigMaxItemSelected) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }